<template>
  <div>
    <div class="content-body">
      <div class="row mt-1 px-2 pt-3">
        <div class="col-md-4 col-xl-2 px-2 mt-2 pt-1 mt-md-0 pt-md-0">
          <div class="card border-0 card-hover card-analytics-one">
            <div class="card-body primary-color dashboard-card">
              <div class="row">
                <div class="col-4 pr-1">
                  <div class="card mt-0 green-card dashboard-icon-card">
                     <font-awesome-icon icon="shopping-cart" class="card-icon" />
                  </div>
                </div>
                <div class="col-8 ml-auto">
                  <!-- <h5 class="font-weight-bold">{{dataSets.purchase_bills}}</h5> -->
                  <h5 class="font-weight-bold text-right">{{countDatas.sales_bills}}</h5>
                </div>
              </div>
              <div class="mt-n3">
                <p class="mb-0 font-weight-bold">Total Sales Bill</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xl-2  px-2 mt-4 pt-2 pt-md-0 pt-xl-0 mt-md-0">
          <div class="card border-0 card-hover card-analytics-one">
            <div class="card-body primary-color dashboard-card">
              <div class="row">
                <div class="col-4 pr-1">
                  <div class="card mt-0 yellow-card dashboard-icon-card">
                     <font-awesome-icon icon="cart-arrow-down" class="card-icon" />
                  </div>
                </div>
                <div class="col-8 ml-auto">
                  <!-- <h5 class="font-weight-bold">{{dataSets.purchase_bills}}</h5> -->
                  <h5 class="font-weight-bold text-right">{{countDatas.purchase_bills}}</h5>
                </div>
              </div>
              <div class="mt-n3">
                <p class="mb-0 font-weight-bold">Total Purchase Entry</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xl-2 px-2 mt-4 pt-2 pt-md-0 pt-xl-0 mt-md-0">
          <div class="card border-0 card-hover card-analytics-one">
            <div class="card-body primary-color dashboard-card">
              <div class="row">
                <div class="col-4 pr-1">
                  <div class="card mt-0 red-card dashboard-icon-card">
                     <font-awesome-icon icon="user" class="card-icon" />
                  </div>
                </div>
                <div class="col-8 ml-auto">
                  <!-- <h5 class="font-weight-bold">{{dataSets.purchase_bills}}</h5> -->
                  <h5 class="font-weight-bold text-right">{{countDatas.total_customers}}</h5>
                </div>
              </div>
              <div class="mt-n3">
                <p class="mb-0 font-weight-bold">Total Customer</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xl-2 px-2 mt-4 pt-2 pt-xl-0 mt-xl-0">
          <div class="card border-0 card-hover card-analytics-one">
            <div class="card-body primary-color dashboard-card">
              <div class="row">
                <div class="col-4 pr-1">
                  <div class="card mt-0 blue-card dashboard-icon-card">
                     <font-awesome-icon icon="user-tag" class="card-icon" />
                  </div>
                </div>
                <div class="col-8 ml-auto">
                  <!-- <h5 class="font-weight-bold">{{dataSets.purchase_bills}}</h5> -->
                  <h5 class="font-weight-bold text-right">{{countDatas.total_suppliers}}</h5>
                </div>
              </div>
              <div class="mt-n3">
                <p class="mb-0 font-weight-bold">Total Supplier</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xl-2 px-2 mt-4 pt-2 pt-xl-0 mt-xl-0">
          <div class="card border-0 card-hover card-analytics-one">
            <div class="card-body primary-color dashboard-card">
              <div class="row">
                <div class="col-4 pr-1">
                  <div class="card mt-0 purple-card dashboard-icon-card">
                     <font-awesome-icon icon="shopping-basket" class="card-icon" />
                  </div>
                </div>
                <div class="col-8 ml-auto">
                  <h5 class="font-weight-bold text-right">{{countDatas.total_items}}</h5>
                </div>
              </div>
              <div class="mt-n3">
                <p class="mb-0 font-weight-bold">Total Items</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xl-2 px-2 mt-4 pt-2 pt-xl-0 mt-xl-0">
          <div class="card border-0 card-hover card-analytics-one">
            <div class="card-body primary-color dashboard-card">
              <div class="row">
                <div class="col-4 pr-1">
                  <div class="card mt-0 dark-blue-card dashboard-icon-card">
                     <font-awesome-icon icon="headset" class="card-icon" />
                     
                  </div>
                </div>
                <div class="col-8 ml-auto">
                  <h5 class="font-weight-bold text-right">{{countDatas.total_leads}}</h5>
                </div>
              </div>
              <div class="mt-n3">
                <p class="mb-0 font-weight-bold">Total Lead</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row px-2">
        <!-- <div class="col-md-9 px-2">
          <div class="card mt-3">
            <div class="row">
              <div class="col-6">
                <h5 class="ml-2 mt-2">Summary & Transaction Control</h5>
              </div>
              <div class="col-6 pr-2">
                <div class="filter-input col-md-6 offset-md-6 mt-2">
                  <select class="float-right">
                    <option>Today</option>
                    <option>Last 30 days</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="barchart m-2">
                  <bar-chart />
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card mt-2 mx-2 p-2 primary-color">
                      <div class="row">
                        <div class="col-6">
                          <span class="font-s">Total Opening Cash</span>
                          <h5 class="mb-0 tx-500">NPR 4,00,000</h5>
                        </div>
                        <div class="col-6">
                          <span class="font-s">Total Closing Cash</span>
                          <h5 class="mb-0 tx-500">NPR 5,00,000</h5>
                        </div>
                      </div>
                    </div>
                    <div class="card my-2 mx-2 p-2 primary-color">
                      <div class="row">
                        <div class="col-6">
                          <span class="font-s">User Opening Cash</span>
                          <h5 class="mb-0 tx-500">NPR 4,00,000</h5>
                        </div>
                        <div class="col-6">
                          <span class="font-s">User Closing Cash</span>
                          <h5 class="mb-0 tx-500">NPR 5,00,000</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="col-md-3 mt-3 px-2">
          <v-calendar :attributes="attrs" :pane-width="900" />
        </div>
      </div>
      <!-- <div class="row mt-4 px-2">
        <div class="col-md-9 mt-1">
          <div class="row">
            <div class="col-md-6 px-2">
              <div class="card bg-theam-secondary">
                <div class="container">
                  <div class="card table-card">
                    Recent Transaction
                  </div>
                </div>
                <div class="px-2 pb-2 mt-n1 card-min-height">
                  <table class="table table1 table-hover" id="tableData">
                    <tbody>
                      <tr>
                        <td>
                          <div class="icon-container bg-primary">
                            <font-awesome-icon icon="cart-arrow-down" class="card-icon" />
                          </div>
                        </td>
                        <td>
                          <div class="td-title">Sales - #2 from Customer: Softechpark Pvt. Ltd.</div>
                          <span class="tx-11 tx-sans tx-color-04 mg-t-2">Today - 5:00 AM</span>
                        </td>
                        <td class="text-right table-end-item">
                          <div class="td-title">NPR 500000</div>
                          <span class="tx-11 tx-sans tx-color-04 mg-t-2">Paid NPR 50000 via Cash</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="icon-container bg-success">
                            <font-awesome-icon icon="cart-arrow-down" class="card-icon" />
                          </div>
                        </td>
                        <td>
                          <div class="td-title">Sales - #2 from Customer: Softechpark Pvt. Ltd.</div>
                          <span class="tx-11 tx-sans tx-color-04 mg-t-2">Today - 5:00 AM</span>
                        </td>
                        <td class="text-right table-end-item">
                          <div class="td-title">NPR 500000</div>
                          <span class="tx-11 tx-sans tx-color-04 mg-t-2">Paid NPR 50000 via Cash</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="text-right">
                    <button class="btn btn-link font-s">View More...</button>
                  </div>  
                </div>
                
              </div>
            </div>
            <div class="col-md-6 px-2">
              <div class="row">
                <div class="col-md-6 mt-4 pt-1 pt-md-0 mt-md-0">
                  <div class="card customer-card bg-success">
                    <div class="card-body" style="padding: 2px">
                      <h6 class="text-color-white mt-1">Total Customer Due</h6>
                      <h5 class="text-color-white">NPR 200000</h5>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-2 pt-1 pt-md-0 mt-md-0">
                  <div class="card supplier-card">
                    <div class="card-body" style="padding: 2px">
                      <h6 class="text-color-white mt-1">Total Supplier Due</h6>
                      <h5 class="text-color-white">NPR {{dataLists2.supplier_due}}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="card mt-3">
                    <div class="container">
                      <div class="card table-card">
                        Payment Report
                      </div>
                    </div>
                    <div>
                      <pie-chart  :style="pieChartStyle" :chartData="chartData" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 px-2 mt-3 mt-md-n3">
          <div class="card-body ht-100p sticky-notes-card">
            <h6 class="text-center">Sticky Notes</h6>
            <hr />
            <textarea name="" id="" class="sticky-note-textarea"></textarea>
          </div>
        </div>
      </div> -->
      <!-- <div class="row mt-4 px-2">
        <div class="col-md-4 mt-1 px-2">
          <div class="card bg-theam-secondary">
                <div class="container">
                  <div class="card table-card">
                    Latest Lead Follow Up
                  </div>
                </div>
                <div class="px-2 pb-2 mt-n1 card-min-height">
                  <table class="table table1 table-hover" id="tableData">
                    <tbody>
                      <tr>
                        <td>
                          <div class="td-title">Softechpark Pvt. Ltd.</div>
                          <span class="tx-11 tx-sans tx-color-04 mg-t-2">Today - 5:00 AM</span>
                        </td>
                        <td class="text-right table-end-item">
                          <div class="badge badge-primary mt-1">Phone Call</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="td-title">Softechpark Pvt. Ltd.</div>
                          <span class="tx-11 tx-sans tx-color-04 mg-t-2">Today - 5:00 AM</span>
                        </td>
                        <td class="text-right table-end-item">
                          <div class="badge badge-success mt-1">Meeting</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="text-right">
                    <button class="btn btn-link font-s">View More...</button>
                  </div>  
                </div>
              </div>
        </div>
        <div class="col-md-8 mt-4 pt-1 pt-md-0 mt-sm-1 px-2">
          <div class="card bg-theam-secondary px-1">
            <div class="container">
              <div class="card table-card">
                Employee Status
              </div>
            </div>
            <div class="px-2 pb-2 mt-n1">
              <table class="table table5 table-hover" >
                <thead class="dashboard-table-head">
                  <tr class="dashboard-table-head">
                    <th class="wd-5p table-start-item">SN</th>
                    <th class="wd-40p">Name</th>
                    <th class="wd-20p">Mobile</th>
                    <th class="wd-20p">Address</th>
                    <th class="wd-15p">Attendence</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="table-start-item">1</td>
                    <td>Bhagwan Das Agrahari</td>
                    <td>9840680875</td>
                    <td>Tinkune, Ktahmndu</td>
                    <td>Present</td>
                  </tr>
                  <tr>
                    <td class="table-start-item">1</td>
                    <td>Bhagwan Das Agrahari</td>
                    <td>9840680875</td>
                    <td>Tinkune, Ktahmndu</td>
                    <td>Present</td>
                  </tr>
                </tbody>
              </table>
              <div class="text-right">
                <button class="btn btn-link font-s">View More...</button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
/* disable eslint */
import { mapGetters } from "vuex";
import { Search } from "../../../mixins/search";
import Services from "./Services/Services";
// import BarChart from "../components/BarChart.vue";
// import PieChart from "../components/PieChart.vue";

export default {
    mixins: [Search],

  components: {
    // BarChart,
    // PieChart,
  },
  data() {
    return {
      pieChartStyle:{
        height:"300px",
        width:"100%",
      },
      attrs: [
        {
          key: "today",
          highlight: true,
          dates: new Date(),
        },
      ],
      chartData: [
      ],
    };
  },

   computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "dataLists3",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("dashboards",["countDatas"]),
  },
  methods: {
     getData() {
      this.getUpperCardsCount();
      this.$store.commit("getData2", `api/dashboard/duecards`);
      this.$store.commit("getData3", `api/dashboard/piechart`);
    },
    getUpperCardsCount(){
      Services.getUpperCardCountDatas().then(res=>{
        this.$store.commit("dashboards/setCountDatas",res.data.data);
      }).catch(err=>{
        console.log(err);
      });
    }
  },
   beforeMount() {
    this.getData();
    
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
    dataLists3(value){
      if (value.length!=0){
        this.chartData.push(value.total_sales);
        this.chartData.push(value.total_purchase);
        this.chartData.push(value.total_income);
        this.chartData.push(value.total_expense);
      }}
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
  
};
</script>
